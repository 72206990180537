var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { hidden: _vm.cancelModal },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "div",
                          { staticClass: "history-button lock-container" },
                          [
                            _c("div", { staticClass: "ml-1 mr-1" }, [
                              _vm._v(_vm._s(_vm.$t("lock"))),
                            ]),
                            _c("b-form-checkbox", {
                              attrs: {
                                switch: "",
                                disabled: !_vm.canEdit(_vm.permissionName, [
                                  "readOnly",
                                ]),
                              },
                              model: {
                                value: _vm.booking.readOnly,
                                callback: function ($$v) {
                                  _vm.$set(_vm.booking, "readOnly", $$v)
                                },
                                expression: "booking.readOnly",
                              },
                            }),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "secondary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.historyShow = true
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clock-rotate-left"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.history")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.canEdit() && _vm.canAdd()
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "auto" },
                          attrs: {
                            disabled: _vm.booking.readOnly,
                            size: "sm",
                            variant: "secondary",
                          },
                          on: { click: _vm.splitBooking },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("button.split_booking")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.canEdit() || !_vm.exists
                    ? [
                        _vm.submittedBy == "ok"
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: _vm.disableOk(),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOkBtnClicked()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                  _vm.canEdit() || !_vm.exists
                    ? [
                        _vm.submittedBy == "apply"
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: _vm.disableOk(),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleApplyBtnClicked()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.apply")))]
                            ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
              _c(
                "ul",
                { staticClass: "mb-0", attrs: { show: _vm.showErrorDetail } },
                [
                  _vm._l(_vm.alertMsgDetails, function (item, index) {
                    return [_c("li", { key: index }, [_vm._v(_vm._s(item))])]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container pl-0" },
            [
              _c(
                "b-row",
                [
                  _vm.isNameVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", lg: "8" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("booking.field.name"),
                                "label-for": "name",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: { required: true },
                                        expression: "{ required: true }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      id: "booking-name",
                                      type: "text",
                                      minMatchingChars: 0,
                                      maxMatches: 100,
                                      "data-vv-as":
                                        _vm.$t("booking.field.name"),
                                      "data-vv-name": "booking.name",
                                      maxlength: _vm.maxNameLength,
                                      "data-vv-delay": "500",
                                      readonly: _vm.isNameReadOnly,
                                      state:
                                        _vm.fieldValidateUtil.stateValidate(
                                          _vm.isReadOnly,
                                          _vm.veeFields,
                                          _vm.errors,
                                          "booking.name"
                                        ),
                                    },
                                    model: {
                                      value: _vm.booking.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, "name", $$v)
                                      },
                                      expression: "booking.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  staticClass: "alert-danger form-field-alert",
                                  class: { "d-block": _vm.showNameError },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "circle-exclamation"],
                                    },
                                  }),
                                  _vm._v(
                                    "  " +
                                      _vm._s(_vm.errors.first("booking.name")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["name"] != null
                    ? _vm._l(
                        _vm.customFieldMap["name"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "name" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isIdentifierVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("field.identifier"),
                                "label-for": "identifier",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "identifier",
                                      type: "text",
                                      "data-vv-as": _vm.$t("field.identifier"),
                                      "data-vv-name": "booking.identifier",
                                      maxlength: _vm.maxIdentifierLength,
                                      disabled: _vm.isIdentifierReadOnly,
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.booking.identifier,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, "identifier", $$v)
                                      },
                                      expression: "booking.identifier",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["identifier"] != null
                    ? _vm._l(
                        _vm.customFieldMap["identifier"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "identifier" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.customFieldMap["default"] != null
                    ? _vm._l(
                        _vm.customFieldMap["default"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isProjectVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("booking.field.project"))),
                              ]),
                              !_vm.isProjectReadOnly
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-action",
                                      attrs: {
                                        id: "PROJECT_ADD",
                                        disabled: _vm.isProjectReadOnly,
                                      },
                                      on: { click: _vm.projectSelectorToggle },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "PROJECT_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "booking.button.project_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("BadgeGroup", {
                                attrs: { readOnly: _vm.isProjectReadOnly },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text: "" + item.name,
                                              variant: "primary",
                                              pillable: !!item.pillable,
                                              readOnly: _vm.isProjectReadOnly,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.projectBadgeRemove(
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.projectBadgeClick(
                                                  item.uuId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  786092951
                                ),
                                model: {
                                  value: _vm.project,
                                  callback: function ($$v) {
                                    _vm.project = $$v
                                  },
                                  expression: "project",
                                },
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  staticClass: "alert-danger form-field-alert",
                                  class: { "d-block": _vm.showProjectError },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "circle-exclamation"],
                                    },
                                  }),
                                  _vm._v(
                                    "  " +
                                      _vm._s(_vm.errors.first("project")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["projects"] != null
                    ? _vm._l(
                        _vm.customFieldMap["projects"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "projects" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isDescriptionVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("booking.field.description"),
                                "label-for": "booking-description",
                              },
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "booking-description",
                                  placeholder: _vm.isDescriptionReadOnly
                                    ? ""
                                    : _vm.$t("booking.placeholder.description"),
                                  "data-vv-as": _vm.$t(
                                    "booking.field.description"
                                  ),
                                  "data-vv-name": "booking.description",
                                  "data-vv-delay": "500",
                                  "max-rows": 6,
                                  readonly: _vm.isDescriptionReadOnly,
                                  trim: "",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.booking.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.booking, "description", $$v)
                                  },
                                  expression: "booking.description",
                                },
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  staticClass: "alert-danger form-field-alert",
                                  class: {
                                    "d-block": _vm.showDescriptionError,
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "circle-exclamation"],
                                    },
                                  }),
                                  _vm._v(
                                    "  " +
                                      _vm._s(
                                        _vm.errors.first("booking.description")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["description"] != null
                    ? _vm._l(
                        _vm.customFieldMap["description"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "description" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isStartTimeVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", xl: "6" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "booking.field.startTime"
                                        ),
                                        "label-for": "booking-startDate",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          id: "booking-startDate",
                                          "today-button": "",
                                          "reset-button": "",
                                          "close-button": "",
                                          "hide-header": "",
                                          "label-today-button":
                                            _vm.$t("date.today"),
                                          "label-reset-button":
                                            _vm.$t("date.reset"),
                                          "label-close-button":
                                            _vm.$t("date.close"),
                                          "today-button-variant": "primary",
                                          "reset-button-variant": "danger",
                                          "close-button-variant": "secondary",
                                          disabled: _vm.isStartTimeReadOnly,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.dateTimeInputHandler(
                                              $event,
                                              "startDate"
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function (ref) {
                                                return [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "calendar-days",
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2621928167
                                        ),
                                        model: {
                                          value: _vm.duration.startDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.duration,
                                              "startDate",
                                              $$v
                                            )
                                          },
                                          expression: "duration.startDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("Timepicker", {
                                    attrs: {
                                      disableTime: _vm.isStartTimeReadOnly,
                                    },
                                    model: {
                                      value: _vm.duration.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.duration, "startTime", $$v)
                                      },
                                      expression: "duration.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["startTime"] != null
                    ? _vm._l(
                        _vm.customFieldMap["startTime"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "startTime" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isCloseTimeVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", xl: "6" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "booking.field.closeTime"
                                        ),
                                        "label-for": "booking-closeDate",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          id: "booking-closeDate",
                                          "today-button": "",
                                          "reset-button": "",
                                          "close-button": "",
                                          "hide-header": "",
                                          "label-today-button":
                                            _vm.$t("date.today"),
                                          "label-reset-button":
                                            _vm.$t("date.reset"),
                                          "label-close-button":
                                            _vm.$t("date.close"),
                                          "today-button-variant": "primary",
                                          "reset-button-variant": "danger",
                                          "close-button-variant": "secondary",
                                          disabled: _vm.isCloseTimeReadOnly,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.dateTimeInputHandler(
                                              $event,
                                              "closeDate"
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function (ref) {
                                                return [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "calendar-days",
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2621928167
                                        ),
                                        model: {
                                          value: _vm.duration.closeDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.duration,
                                              "closeDate",
                                              $$v
                                            )
                                          },
                                          expression: "duration.closeDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("Timepicker", {
                                    attrs: {
                                      disableTime: _vm.isCloseTimeReadOnly,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.dateTimeInputHandler(
                                          $event,
                                          "closeTime"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.duration.closeTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.duration, "closeTime", $$v)
                                      },
                                      expression: "duration.closeTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["closeTime"] != null
                    ? _vm._l(
                        _vm.customFieldMap["closeTime"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "closeTime" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isFixedDurationVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("task.field.fixedDuration"),
                                "label-for": "task-fixed-duration",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  !_vm.isFixedDurationReadOnly
                                    ? _c(
                                        "b-input-group-prepend",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                id: "FIXED_DURATION_SUBTRACT",
                                                disabled:
                                                  _vm.disableFixedDuration,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.fixedDurationAddMinus(
                                                    -1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "minus"],
                                                },
                                              }),
                                              _c("b-popover", {
                                                attrs: {
                                                  target:
                                                    "FIXED_DURATION_SUBTRACT",
                                                  placement: "top",
                                                  triggers: "hover",
                                                  content: _vm.$t(
                                                    "task.button.duration_subtract"
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("b-form-input", {
                                    class: _vm.fixedDurationClass,
                                    attrs: {
                                      id: "task-fixed-duration",
                                      type: "text",
                                      disabled: _vm.disableFixedDuration,
                                      "data-vv-as": _vm.$t(
                                        "task.field.fixedDuration"
                                      ),
                                      "data-vv-name": "booking.fixedDuration",
                                      "data-vv-delay": "500",
                                      readonly: _vm.isFixedDurationReadOnly,
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        return _vm.onFixedDurationKeyDown(
                                          $event
                                        )
                                      },
                                      keyup: function ($event) {
                                        return _vm.onFixedDurationKeyUp($event)
                                      },
                                      change: _vm.onFixedDurationChange,
                                    },
                                    model: {
                                      value: _vm.booking.fixedDuration,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.booking,
                                          "fixedDuration",
                                          $$v
                                        )
                                      },
                                      expression: "booking.fixedDuration",
                                    },
                                  }),
                                  !_vm.isFixedDurationReadOnly
                                    ? _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                id: "FIXED_DURATION_ADD",
                                                disabled:
                                                  _vm.disableFixedDuration,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.fixedDurationAddMinus(
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "plus"],
                                                },
                                              }),
                                              _c("b-popover", {
                                                attrs: {
                                                  target: "FIXED_DURATION_ADD",
                                                  placement: "top",
                                                  triggers: "hover",
                                                  content: _vm.$t(
                                                    "task.button.duration_add"
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.fixedDurationAlert
                                    ? _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "fixed-duration-alert",
                                              attrs: {
                                                id: "fixed-duration-alert",
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target: "fixed-duration-alert",
                                                triggers: "hover",
                                                placement: "topleft",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.fixedDurationAlertMsg
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  staticClass: "alert-danger form-field-alert",
                                  class: {
                                    "d-block": _vm.showFixedDurationError,
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "circle-exclamation"],
                                    },
                                  }),
                                  _vm._v(
                                    "  " +
                                      _vm._s(
                                        _vm.errors.first("fixedDuration")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["fixedDuration"] != null
                    ? _vm._l(
                        _vm.customFieldMap["fixedDuration"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "fixedDuration" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isDurationVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("booking.field.duration"),
                                "label-for": "booking-duration",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  !_vm.isDurationReadOnly
                                    ? _c(
                                        "b-input-group-prepend",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                id: "DURATION_SUBTRACT",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.durationAddMinus(
                                                    -1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "minus"],
                                                },
                                              }),
                                              _c("b-popover", {
                                                attrs: {
                                                  target: "DURATION_SUBTRACT",
                                                  placement: "top",
                                                  triggers: "hover",
                                                  content: _vm.$t(
                                                    "booking.button.duration_subtract"
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "booking-duration",
                                      type: "text",
                                      "data-vv-as": _vm.$t(
                                        "booking.field.duration"
                                      ),
                                      "data-vv-name": "duration.value",
                                      "data-vv-delay": "500",
                                      readonly: _vm.isDurationReadOnly,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.dateTimeInputHandler(
                                          $event,
                                          "duration"
                                        )
                                      },
                                      keydown: function ($event) {
                                        return _vm.onDurationKeyDown($event)
                                      },
                                      keyup: function ($event) {
                                        return _vm.onDurationKeyUp($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.duration.value,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.duration, "value", $$v)
                                      },
                                      expression: "duration.value",
                                    },
                                  }),
                                  !_vm.isDurationReadOnly
                                    ? _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: { id: "DURATION_ADD" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.durationAddMinus(1)
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "plus"],
                                                },
                                              }),
                                              _c("b-popover", {
                                                attrs: {
                                                  target: "DURATION_ADD",
                                                  placement: "top",
                                                  triggers: "hover",
                                                  content: _vm.$t(
                                                    "booking.button.duration_add"
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  staticClass: "alert-danger form-field-alert",
                                  class: { "d-block": _vm.showDurationError },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "circle-exclamation"],
                                    },
                                  }),
                                  _vm._v(
                                    "  " +
                                      _vm._s(
                                        _vm.errors.first("duration.value")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["estimatedDuration"] != null
                    ? _vm._l(
                        _vm.customFieldMap["estimatedDuration"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "estimatedDuration" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isStageVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", sm: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "field-stage",
                                label: _vm.$t("task.field.stage"),
                                "label-for": "task-stage",
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "task-stage",
                                  options: _vm.optionStage,
                                  disabled: _vm.isStageReadOnly,
                                  "text-field": "label",
                                  "value-field": "key",
                                },
                                model: {
                                  value: _vm.stage,
                                  callback: function ($$v) {
                                    _vm.stage = $$v
                                  },
                                  expression: "stage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["stage"] != null
                    ? _vm._l(
                        _vm.customFieldMap["stage"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "stage" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isColorVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", sm: "2" } },
                        [
                          _c("Color", {
                            attrs: {
                              update: _vm.updatedColor,
                              disabled: _vm.isColorReadOnly,
                            },
                            model: {
                              value: _vm.booking.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.booking, "color", $$v)
                              },
                              expression: "booking.color",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["color"] != null
                    ? _vm._l(
                        _vm.customFieldMap["color"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "color" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isStaffVisible || _vm.isResourceVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12" } },
                        [
                          _vm.dataMode === "staff"
                            ? _c(
                                "b-form-group",
                                [
                                  _c("label", { staticClass: "mr-1" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("booking.field.staffs"))
                                    ),
                                  ]),
                                  !_vm.isStaffReadOnly
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn-action",
                                          attrs: { id: "STAFF_ADD" },
                                          on: {
                                            click: _vm.staffSelectorToggle,
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: ["far", "plus"] },
                                          }),
                                          _c("b-popover", {
                                            attrs: {
                                              target: "STAFF_ADD",
                                              placement: "top",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                "booking.button.staff_add"
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("BadgeGroup", {
                                    attrs: { readOnly: _vm.isStaffReadOnly },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var item = ref.item
                                            var index = ref.index
                                            return [
                                              _c("Badge", {
                                                key: index,
                                                attrs: {
                                                  text:
                                                    item.name +
                                                    " (" +
                                                    (item.quantity > 1
                                                      ? item.quantity + " x "
                                                      : "") +
                                                    (item.utilization
                                                      ? Math.trunc(
                                                          item.utilization * 100
                                                        )
                                                      : 100) +
                                                    "%)",
                                                  variant: item.genericStaff
                                                    ? "info"
                                                    : _vm.staffUsage[item.name]
                                                    ? "warning"
                                                    : "primary",
                                                  errorMessage:
                                                    _vm.staffUsage[item.name] ||
                                                    null,
                                                  pillable: !!item.pillable,
                                                  readOnly: _vm.isStaffReadOnly,
                                                },
                                                on: {
                                                  badgeRemove: function (
                                                    $event
                                                  ) {
                                                    return _vm.staffBadgeRemove(
                                                      index
                                                    )
                                                  },
                                                  badgeClick: function (
                                                    $event
                                                  ) {
                                                    return _vm.staffBadgeClick(
                                                      item.uuId
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      819429473
                                    ),
                                    model: {
                                      value: _vm.staffs,
                                      callback: function ($$v) {
                                        _vm.staffs = $$v
                                      },
                                      expression: "staffs",
                                    },
                                  }),
                                  _c(
                                    "b-form-invalid-feedback",
                                    {
                                      staticClass:
                                        "alert-danger form-field-alert",
                                      class: { "d-block": _vm.showStaffError },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "circle-exclamation"],
                                        },
                                      }),
                                      _vm._v(
                                        "  " +
                                          _vm._s(_vm.errors.first("staffs")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.dataMode === "resource"
                            ? _c(
                                "b-form-group",
                                [
                                  _c("label", { staticClass: "mr-1" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.field.resources"))
                                    ),
                                  ]),
                                  !_vm.isResourceReadOnly
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn-action",
                                          attrs: { id: "RESOURCE_ADD" },
                                          on: {
                                            click: _vm.resourceSelectorToggle,
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: ["far", "plus"] },
                                          }),
                                          _c("b-popover", {
                                            attrs: {
                                              target: "RESOURCE_ADD",
                                              placement: "top",
                                              triggers: "hover",
                                              content: _vm.$t(
                                                "task.button.resource_add"
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("BadgeGroup", {
                                    attrs: { readOnly: _vm.isResourceReadOnly },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var item = ref.item
                                            var index = ref.index
                                            return [
                                              _c("Badge", {
                                                key: index,
                                                attrs: {
                                                  text:
                                                    item.name +
                                                    " (" +
                                                    (item.quantity > 1
                                                      ? item.quantity + " x "
                                                      : "") +
                                                    (item.utilization
                                                      ? item.utilization * 100
                                                      : 100) +
                                                    "%)",
                                                  variant: "primary",
                                                  pillable: !!item.pillable,
                                                  readOnly:
                                                    _vm.isResourceReadOnly,
                                                },
                                                on: {
                                                  badgeRemove: function (
                                                    $event
                                                  ) {
                                                    return _vm.resourceBadgeRemove(
                                                      index
                                                    )
                                                  },
                                                  badgeClick: function (
                                                    $event
                                                  ) {
                                                    return _vm.resourceBadgeClick(
                                                      item.uuId
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1272292794
                                    ),
                                    model: {
                                      value: _vm.resources,
                                      callback: function ($$v) {
                                        _vm.resources = $$v
                                      },
                                      expression: "resources",
                                    },
                                  }),
                                  _c(
                                    "b-form-invalid-feedback",
                                    {
                                      staticClass:
                                        "alert-danger form-field-alert",
                                      class: {
                                        "d-block": _vm.showResourceError,
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "circle-exclamation"],
                                        },
                                      }),
                                      _vm._v(
                                        "  " +
                                          _vm._s(
                                            _vm.errors.first("resources")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["staffs"] != null
                    ? _vm._l(
                        _vm.customFieldMap["staffs"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "staffs" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isFixedCostNetVisible || _vm.isFixedCostVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-select",
                            {
                              staticClass: "title-dropdown mb-1",
                              model: {
                                value: _vm.fixedCostMode,
                                callback: function ($$v) {
                                  _vm.fixedCostMode = $$v
                                },
                                expression: "fixedCostMode",
                              },
                            },
                            [
                              _vm.isFixedCostVisible
                                ? _c("option", { attrs: { value: "gross" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.field.fixedCost"))
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isFixedCostNetVisible
                                ? _c("option", { attrs: { value: "net" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.field.fixedCostNet"))
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-input-group",
                                [
                                  _vm.fixedCostMode === "gross"
                                    ? _c("b-form-input", {
                                        attrs: {
                                          id: "cost",
                                          type: "number",
                                          min: 0.0,
                                          step: 1,
                                          "lazy-formatter": "",
                                          formatter: _vm.floatFormatter(
                                            null,
                                            0.0
                                          ),
                                          "data-vv-as": _vm.$t(
                                            "task.field.fixedCost"
                                          ),
                                          "data-vv-name": "booking.fixedCost",
                                          "data-vv-delay": "500",
                                          readonly: _vm.isFixedCostReadOnly,
                                        },
                                        model: {
                                          value: _vm.booking.fixedCost,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.booking,
                                              "fixedCost",
                                              $$v
                                            )
                                          },
                                          expression: "booking.fixedCost",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.fixedCostMode === "net"
                                    ? _c("b-form-input", {
                                        attrs: {
                                          id: "costNet",
                                          type: "number",
                                          min: 0.0,
                                          step: 1,
                                          "lazy-formatter": "",
                                          formatter: _vm.floatFormatter(
                                            null,
                                            0.0
                                          ),
                                          "data-vv-as": _vm.$t(
                                            "task.field.fixedCost"
                                          ),
                                          "data-vv-name": "booking.fixedCost",
                                          "data-vv-delay": "500",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.booking.fixedCostNet,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.booking,
                                              "fixedCostNet",
                                              $$v
                                            )
                                          },
                                          expression: "booking.fixedCostNet",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["fixedCost"] != null
                    ? _vm._l(
                        _vm.customFieldMap["fixedCost"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "fixedCost" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isCurrencyCodeVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("task.field.currencyCode"),
                              },
                            },
                            [
                              _c("b-input-group", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.booking.currencyCode,
                                        expression: "booking.currencyCode",
                                      },
                                    ],
                                    staticClass: "custom-select",
                                    attrs: {
                                      disabled: _vm.isCurrencyCodeReadOnly,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.booking,
                                          "currencyCode",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.optionCurrency,
                                      function (opt, index) {
                                        return [
                                          _c(
                                            "option",
                                            {
                                              key: index,
                                              style: {
                                                display:
                                                  opt.num < 0
                                                    ? "none"
                                                    : "block",
                                              },
                                              domProps: { value: opt.value },
                                            },
                                            [_vm._v(_vm._s(opt.text))]
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  staticClass: "alert-danger form-field-alert",
                                  class: { "d-block": _vm.showCurrencyError },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["far", "circle-exclamation"],
                                    },
                                  }),
                                  _vm._v(
                                    "  " +
                                      _vm._s(
                                        _vm.errors.first("booking.currencyCode")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["currencyCode"] != null
                    ? _vm._l(
                        _vm.customFieldMap["currencyCode"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "currencyCode" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isEstimatedCostVisible || _vm.isEstimatedCostNetVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-select",
                            {
                              staticClass: "title-dropdown mb-1",
                              model: {
                                value: _vm.estimatedCostMode,
                                callback: function ($$v) {
                                  _vm.estimatedCostMode = $$v
                                },
                                expression: "estimatedCostMode",
                              },
                            },
                            [
                              _vm.isEstimatedCostVisible
                                ? _c("option", { attrs: { value: "gross" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.field.estimatedCost"))
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isEstimatedCostNetVisible
                                ? _c("option", { attrs: { value: "net" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("task.field.estimatedCostNet")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-input-group",
                                [
                                  _vm.estimatedCostMode === "gross"
                                    ? _c("b-form-input", {
                                        attrs: {
                                          id: "estimatedCost",
                                          value: _vm.estimatedCost,
                                          readonly: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.estimatedCostMode === "net"
                                    ? _c("b-form-input", {
                                        attrs: {
                                          id: "estimatedCostNet",
                                          value: _vm.estimatedCostNet,
                                          readonly: "",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["estimatedCost"] != null
                    ? _vm._l(
                        _vm.customFieldMap["estimatedCost"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "estimatedCost" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isActualCostVisible || _vm.isActualCostNetVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-select",
                            {
                              staticClass: "title-dropdown mb-1",
                              model: {
                                value: _vm.actualCostMode,
                                callback: function ($$v) {
                                  _vm.actualCostMode = $$v
                                },
                                expression: "actualCostMode",
                              },
                            },
                            [
                              _vm.isActualCostVisible
                                ? _c("option", { attrs: { value: "gross" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.field.actualCost"))
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isActualCostNetVisible
                                ? _c("option", { attrs: { value: "net" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("task.field.actualCostNet"))
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-input-group",
                                [
                                  _vm.actualCostMode === "gross"
                                    ? _c("b-form-input", {
                                        class: _vm.actualCostClass,
                                        attrs: {
                                          id: "actualCost",
                                          value: _vm.actualCost,
                                          readonly: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.actualCostMode === "net"
                                    ? _c("b-form-input", {
                                        class: _vm.actualCostClass,
                                        attrs: {
                                          id: "actualCostNet",
                                          value: _vm.actualCostNet,
                                          readonly: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.actualCostAlert
                                    ? _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass: "work-effort-alert",
                                              attrs: { id: "actualCostAlert" },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target: "actualCostAlert",
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.actualCostAlertMsg
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["actualCost"] != null
                    ? _vm._l(
                        _vm.customFieldMap["actualCost"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "actualCost" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isRebateVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("task.field.rebates"))),
                              ]),
                              !_vm.isRebateReadOnly
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-action",
                                      attrs: {
                                        id: "REBATE_ADD",
                                        disabled: _vm.isRebateReadOnly,
                                      },
                                      on: { click: _vm.rebateSelectorToggle },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "REBATE_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "task.button.rebate_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-text",
                                { staticClass: "rebate-total mr-1" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("task.field.total_rebate", [
                                          _vm.totalRebate,
                                        ])
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("BadgeGroup", {
                                attrs: { readOnly: _vm.isRebateReadOnly },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text:
                                                item.name +
                                                " (" +
                                                _vm.formatRebate(item.rebate) +
                                                "%)",
                                              variant: "primary",
                                              pillable: !!item.pillable,
                                              readOnly: _vm.isRebateReadOnly,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.rebateBadgeRemove(
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.rebateBadgeClick(
                                                  item.uuId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  486676024
                                ),
                                model: {
                                  value: _vm.rebates,
                                  callback: function ($$v) {
                                    _vm.rebates = $$v
                                  },
                                  expression: "rebates",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["rebates"] != null
                    ? _vm._l(
                        _vm.customFieldMap["rebates"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "rebates" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.isNoteVisible
                    ? _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("NoteList", {
                                attrs: {
                                  readOnly: _vm.isNoteReadOnly,
                                  notes: _vm.notes,
                                },
                                on: {
                                  add: _vm.addNote,
                                  edit: _vm.editNote,
                                  toRemove: _vm.removeNote,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.customFieldMap["notes"] != null
                    ? _vm._l(
                        _vm.customFieldMap["notes"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "notes" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                      disabled:
                                        _vm.isReadOnly ||
                                        (_vm.exists &&
                                          !_vm.canEdit(_vm.permissionName, [
                                            field.name,
                                          ])),
                                    },
                                    model: {
                                      value: _vm.booking[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.booking, field.name, $$v)
                                      },
                                      expression: "booking[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: _vm.historyEntityType,
                    customFields: _vm.customFields,
                    links: "STAGE,REBATE,NOTE,STAFF,PROJECT",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.state.staffSelectorShow
        ? _c("StaffSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.staffSelectorShow,
              companies: _vm.project ? _vm.project.company : null,
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffSelectorShow", $event)
              },
              ok: _vm.staffSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.projectSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.projectSelectorShow,
              entityService: _vm.projectUtil,
              entity: "PROJECT",
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "projectSelectorShow", $event)
              },
              ok: _vm.projectSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.staffWorkEffortShow
        ? _c("StaffWorkEffortModal", {
            attrs: {
              childTaskEfforts: _vm.childTasksActualDuration,
              workEfforts: _vm.staffs,
              show: _vm.state.staffWorkEffortShow,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffWorkEffortShow", $event)
              },
              ok: _vm.staffWorkEffortOk,
            },
          })
        : _vm._e(),
      _vm.state.staffUtilizationEditShow
        ? _c("StaffUtilizationModal", {
            attrs: {
              supportsUnit: false,
              show: _vm.state.staffUtilizationEditShow,
              uuId: _vm.staffUtilizationEdit.uuId,
              name: _vm.staffUtilizationEdit.name,
              utilization: _vm.staffUtilizationEdit.utilization,
              unit: _vm.staffUtilizationEdit.unit,
              genericStaff: _vm.staffUtilizationEdit.genericStaff,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffUtilizationEditShow", $event)
              },
              ok: _vm.staffUtilizationOk,
            },
          })
        : _vm._e(),
      _vm._l(_vm.toConfirmStaffs, function (item, index) {
        return [
          item.show
            ? _c("StaffUtilizationModal", {
                key: "staff-" + index,
                attrs: {
                  supportsUnit: false,
                  show: item.show,
                  canApplyAll: _vm.toConfirmStaffs.length > 1,
                  uuId: item.uuId,
                  name: item.name,
                  utilization: item.utilization,
                  unit: item.quantity,
                  genericStaff: item.genericStaff,
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(item, "show", $event)
                  },
                  ok: _vm.toConfirmStaffOk,
                  cancel: _vm.toConfirmStaffCancel,
                },
              })
            : _vm._e(),
        ]
      }),
      _vm.state.resourceSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.resourceSelectorShow,
              entityService: _vm.resourceUtil,
              entity: "RESOURCE",
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceSelectorShow", $event)
              },
              ok: _vm.resourceSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.resourceUnitEditShow
        ? _c("ResourceUnitModal", {
            attrs: {
              show: _vm.state.resourceUnitEditShow,
              uuId: _vm.resourceUnitEdit.uuId,
              name: _vm.resourceUnitEdit.name,
              unit: _vm.resourceUnitEdit.unit,
              utilization: parseFloat(_vm.resourceUnitEdit.utilization),
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceUnitEditShow", $event)
              },
              ok: _vm.resourceUnitOk,
            },
          })
        : _vm._e(),
      _vm._l(_vm.toConfirmResources, function (item, index) {
        return [
          item.show
            ? _c("ResourceUnitModal", {
                key: "resource-" + index,
                attrs: {
                  show: item.show,
                  canApplyAll: _vm.toConfirmResources.length > 1,
                  uuId: item.uuId,
                  name: item.name,
                  unit: item.quantity,
                  utilization: parseFloat(item.utilization),
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(item, "show", $event)
                  },
                  ok: _vm.toConfirmResourceOk,
                  cancel: _vm.toConfirmResourceCancel,
                },
              })
            : _vm._e(),
        ]
      }),
      _vm.state.rebateSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.rebateSelectorShow,
              entityService: _vm.rebateUtil,
              entity: "REBATE",
              nonAdmin: "",
              preselected: _vm.rebateEdit.uuId,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "rebateSelectorShow", $event)
              },
              ok: _vm.rebateSelectorOk,
            },
          })
        : _vm._e(),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.state.durationCalculationShow,
          bookingName: _vm.bookingName,
          resizeMode: _vm.durationCalculation.resizeMode,
          defaultActionForNonWorkPrompt: null,
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projScheduleFromStart,
          taskAutoScheduleMode: _vm.durationCalculation.taskAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          durationConversionOpts: _vm.durationConversionOpts,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "durationCalculationShow", $event)
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          success: _vm.durationCalculationOk,
          cancel: _vm.durationCalculationCancel,
          skip: _vm.durationCalculationCancel,
        },
      }),
      _c("SplitTaskModal", {
        attrs: {
          show: _vm.state.splitBookingModalShow,
          title: _vm.$t("button.split_booking"),
          calendar: _vm.calendar,
          duration: _vm.duration,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "splitBookingModalShow", $event)
          },
          success: _vm.splitBookingSuccess,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }